var status_type = new Array();

status_type[0] = 'New User';
status_type[1] = 'Full Time';
status_type[2] = 'Part Time';
status_type[3] = 'Occassional Visitor';
status_type[4] = 'Casual Visitor';
status_type[5] = 'Not Available';

export default status_type;
