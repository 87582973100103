import React, { Component } from 'react';
import Footer from '../layout/Footer';
import HeaderOnlyLogo from '../layout/HeaderOnlyLogo';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
class PrivacyPolicy extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.auth.user ? <HeaderOnlyLogo /> : ''}
        <div className='container explore-container' id='explore-container'>
          <h1>Welcome to our Privacy Policy</h1>
          <h3>Your privacy is critically important to us.</h3>
          Taskbarter is located at:
          <br />
          <address>
            Lahore <br />
            54000, Pakistan
            <br />
            0923364256811{' '}
          </address>
          <p>
            It is Taskbarter's policy to respect your privacy regarding any
            information we may collect while operating our website. This Privacy
            Policy applies to{' '}
            <a href='https://www.taskbarter.com/'>
              https://www.taskbarter.com/
            </a>{' '}
            (hereinafter, "us", "we", or "https://www.taskbarter.com/"). We
            respect your privacy and are committed to protecting personally
            identifiable information you may provide us through the Website. We
            have adopted this privacy policy ("Privacy Policy") to explain what
            information may be collected on our Website, how we use this
            information, and under what circumstances we may disclose the
            information to third parties. This Privacy Policy applies only to
            information we collect through the Website and does not apply to our
            collection of information from other sources.
          </p>
          <p>
            This Privacy Policy, together with the Terms and conditions posted
            on our Website, set forth the general rules and policies governing
            your use of our Website. Depending on your activities when visiting
            our Website, you may be required to agree to additional terms and
            conditions.
          </p>
          <h2>Website Visitors</h2>
          <p>
            Like most website operators, Taskbarter collects
            non-personally-identifying information of the sort that web browsers
            and servers typically make available, such as the browser type,
            language preference, referring site, and the date and time of each
            visitor request. Taskbarter's purpose in collecting non-personally
            identifying information is to better understand how Taskbarter's
            visitors use its website. From time to time, Taskbarter may release
            non-personally-identifying information in the aggregate, e.g., by
            publishing a report on trends in the usage of its website.
          </p>
          <p>
            Taskbarter also collects potentially personally-identifying
            information like Internet Protocol (IP) addresses for logged in
            users and for users leaving comments on https://www.taskbarter.com/
            blog posts. Taskbarter only discloses logged in user and commenter
            IP addresses under the same circumstances that it uses and discloses
            personally-identifying information as described below.
          </p>
          <h2>Gathering of Personally-Identifying Information</h2>
          <p>
            Certain visitors to Taskbarter's websites choose to interact with
            Taskbarter in ways that require Taskbarter to gather
            personally-identifying information. The amount and type of
            information that Taskbarter gathers depends on the nature of the
            interaction. For example, we ask visitors who sign up for a blog at
            https://www.taskbarter.com/ to provide a username and email address.
          </p>
          <h2>Security</h2>
          <p>
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
          </p>
          <h2>Links To External Sites</h2>
          <p>
            Our Service may contain links to external sites that are not
            operated by us. If you click on a third party link, you will be
            directed to that third party's site. We strongly advise you to
            review the Privacy Policy and terms and conditions of every site you
            visit.
          </p>
          <p>
            We have no control over, and assume no responsibility for the
            content, privacy policies or practices of any third party sites,
            products or services.
          </p>
          <h2>Protection of Certain Personally-Identifying Information</h2>
          <p>
            Taskbarter discloses potentially personally-identifying and
            personally-identifying information only to those of its employees,
            contractors and affiliated organizations that (i) need to know that
            information in order to process it on Taskbarter's behalf or to
            provide services available at Taskbarter's website, and (ii) that
            have agreed not to disclose it to others. Some of those employees,
            contractors and affiliated organizations may be located outside of
            your home country; by using Taskbarter's website, you consent to the
            transfer of such information to them. Taskbarter will not rent or
            sell potentially personally-identifying and personally-identifying
            information to anyone. Other than to its employees, contractors and
            affiliated organizations, as described above, Taskbarter discloses
            potentially personally-identifying and personally-identifying
            information only in response to a subpoena, court order or other
            governmental request, or when Taskbarter believes in good faith that
            disclosure is reasonably necessary to protect the property or rights
            of Taskbarter, third parties or the public at large.
          </p>
          <p>
            If you are a registered user of https://www.taskbarter.com/ and have
            supplied your email address, Taskbarter may occasionally send you an
            email to tell you about new features, solicit your feedback, or just
            keep you up to date with what's going on with Taskbarter and our
            products. We primarily use our blog to communicate this type of
            information, so we expect to keep this type of email to a minimum.
            If you send us a request (for example via a support email or via one
            of our feedback mechanisms), we reserve the right to publish it in
            order to help us clarify or respond to your request or to help us
            support other users. Taskbarter takes all measures reasonably
            necessary to protect against the unauthorized access, use,
            alteration or destruction of potentially personally-identifying and
            personally-identifying information.
          </p>
          <h2>Aggregated Statistics</h2>
          <p>
            Taskbarter may collect statistics about the behavior of visitors to
            its website. Taskbarter may display this information publicly or
            provide it to others. However, Taskbarter does not disclose your
            personally-identifying information.
          </p>
          <h2>Cookies</h2>
          <p>
            To enrich and perfect your online experience, Taskbarter uses
            "Cookies", similar technologies and services provided by others to
            display personalized content, appropriate advertising and store your
            preferences on your computer.
          </p>
          <p>
            A cookie is a string of information that a website stores on a
            visitor's computer, and that the visitor's browser provides to the
            website each time the visitor returns. Taskbarter uses cookies to
            help Taskbarter identify and track visitors, their usage of
            https://www.taskbarter.com/, and their website access preferences.
            Taskbarter visitors who do not wish to have cookies placed on their
            computers should set their browsers to refuse cookies before using
            Taskbarter's websites, with the drawback that certain features of
            Taskbarter's websites may not function properly without the aid of
            cookies.
          </p>
          <p>
            By continuing to navigate our website without changing your cookie
            settings, you hereby acknowledge and agree to Taskbarter's use of
            cookies.
          </p>
          <h2>Privacy Policy Changes</h2>
          <p>
            Although most changes are likely to be minor, Taskbarter may change
            its Privacy Policy from time to time, and in Taskbarter's sole
            discretion. Taskbarter encourages visitors to frequently check this
            page for any changes to its Privacy Policy. Your continued use of
            this site after any change in this Privacy Policy will constitute
            your acceptance of such change.
          </p>
          <h2>Third Party Services</h2>
          <p>
            For hosting the web app on cloud, we are using Heroku Services. The
            service is mainly responsible for hosting code files of the server
            as well as handling and executing them at specified time.
            <br />
            For data management and storage, we are using MongoDB. The database
            is hosted on MongoDB Atlas cloud. The service is mainly responsible
            for storing personal data as well as other data that is on the
            website.
            <br />
            For deployment and versioning, we are using Github Services.
          </p>
          <h2>Contact Information</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us via <a href='mailto:taskbarter@gmail.com'>email</a> or{' '}
            <a href='tel:0923364256811'>phone</a>.
          </p>
        </div>
        <Footer />
        <MetaTags>
          <title>Privacy Policy | Taskbarter</title>
        </MetaTags>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(mapStateToProps, {})(PrivacyPolicy));
