export const GET_ERRORS = 'GET_ERRORS';
export const USER_LOADING = 'USER_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const ADD_TASK = 'ADD_TASK';
export const GET_TASK = 'GET_TASK';
export const GET_TASKS = 'GET_TASKS';
export const DELETE_TASK = 'DELETE_TASK';
export const TASK_LOADING = 'TASK_LOADING';
export const TASKS_COUNT_LOADING = 'TASKS_COUNT_LOADING';
export const GET_TASKS_COUNT = 'GET_TASKS_COUNT';
export const EMPTY_TASKS = 'EMPTY_TASKS';
export const APPEND_TASKS = 'APPEND_TASKS';
export const SET_WORKPLACE_TASKS = 'SET_WORKPLACE_TASKS';
export const SET_SOCKET_CONNECTION = 'SET_SOCKET_CONNECTION';
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_UNSEEN_CONVO = 'SET_UNSEEN_CONVO';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_SKILL = 'REMOVE_SKILL';
export const ADD_PUBLISHED_TASKS = 'ADD_PUBLISHED_TASKS';
export const ADD_WORKING_TASKS = 'ADD_WORKING_TASKS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const ADD_AVAILABLE_TASKS = 'ADD_AVAILABLE_TASKS';
export const ADD_COMPLETED_TASKS = 'ADD_COMPLETED_TASKS';
export const ADD_ARCHIVED_TASKS = 'ADD_ARCHIVED_TASKS';
export const ADD_ASSIGNED_TASKS = 'ADD_ASSIGNED_TASKS';
export const ADD_PAUSED_TASKS = 'ADD_PAUSED_TASKS';
export const ADD_CURRENTLY_WORKING_TASK = 'ADD_CURRENTLY_WORKING_TASKS';
export const ADD_COMPLETED_WORKING_TASK = 'ADD_COMPLETED_WORKING_TASKS';
export const RESET_SOCKET_CONNECTION = 'RESET_SOCKET_CONNECTION';
export const SET_RATING = 'SET_RATING';
export const ALL_NOTIFICATIONS_READ = 'ALL_NOTIFICATIONS_READ';
export const ADD_PENDING_PROPOSALS = 'ADD_PENDING_PROPOSALS';

export const ADMIN_ACTIVITIES = 'ADMIN_ACTIVITIES';
export const ADMIN_USERS = 'ADMIN_USERS';
export const ADMIN_TASKS = 'ADMIN_TASKS';
